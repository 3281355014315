import { template as template_5da41cf104de4ac98258ca26bc623b88 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5da41cf104de4ac98258ca26bc623b88(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
