import { template as template_a0c779977b5c48bebb17e51ad733b8d8 } from "@ember/template-compiler";
const FKLabel = template_a0c779977b5c48bebb17e51ad733b8d8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
